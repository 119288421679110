import React from "react";

import {
  Container,
  Glass,
  Image,
  BgImageOne,
  BgImageTwo,
} from "./styles/error";

export default function Error({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Error.Glass = function ErrorGlass({ children, ...restProps }) {
  return <Glass {...restProps}>{children}</Glass>;
};

Error.Image = function ErrorImage({ children, ...restProps }) {
  return <Image {...restProps} src="/images/Error.png" alt="Error"></Image>;
};

Error.BgImageOne = function ErrorBgImage({
  top,
  right,
  children,
  ...restProps
}) {
  return (
    <BgImageOne
      top={top}
      right={right}
      {...restProps}
      src="/images/Error.png"
      alt="Error"
    ></BgImageOne>
  );
};

Error.BgImageTwo = function ErrorBgImage({
  top,
  right,
  children,
  ...restProps
}) {
  return (
    <BgImageTwo
      top={top}
      right={right}
      {...restProps}
      src="/images/Error.png"
      alt="Error"
    ></BgImageTwo>
  );
};
