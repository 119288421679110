import React from "react";

import {
  Container,
  Hero,
  TextArea,
  Image,
  Heading,
  Description,
  GlassH,
  GlassV,
  DarkBg,
  LightGlassV,
  SecHeading,
  SecDescription,
  Circle,
  Cards,
  Section,
  GlassTitle,
  GlassDescription,
  GlassSecDescription,
  Diagram,
} from "./styles/oncology";

export default function Oncology({ background, children, ...restProps }) {
  return (
    <Container background={background} {...restProps}>
      {children}
    </Container>
  );
}

Oncology.DarkBg = function OncologyDarkBg({ children, ...restProps }) {
  return <DarkBg {...restProps}>{children}</DarkBg>;
};

Oncology.Hero = function OncologyHero({ children, ...restProps }) {
  return <Hero {...restProps}>{children}</Hero>;
};

Oncology.TextArea = function OncologyTextArea({ children, ...restProps }) {
  return <TextArea {...restProps}>{children}</TextArea>;
};

Oncology.Heading = function OncologyHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Oncology.SecHeading = function OncologySecHeading({ children, ...restProps }) {
  return <SecHeading {...restProps}>{children}</SecHeading>;
};

Oncology.SecDescription = function OncologySecDescription({
  children,
  ...restProps
}) {
  return <SecDescription {...restProps}>{children}</SecDescription>;
};

Oncology.Section = function OncologySection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Oncology.Description = function OncologyDescription({
  children,
  ...restProps
}) {
  return <Description {...restProps}>{children}</Description>;
};

Oncology.Image = function OncologyImage({
  src,
  width,
  children,
  ...restProps
}) {
  return (
    <Image width={width} {...restProps}>
      <img src={`/images/${src}.png`} alt="oncology" />
    </Image>
  );
};

Oncology.Diagram = function OncologyDiagram({ src, children, ...restProps }) {
  return (
    <Diagram {...restProps}>
      <img src={`/images/${src}.png`} alt="Diagram" />
    </Diagram>
  );
};

Oncology.GlassH = function OncologyGlassH({ children, ...restProps }) {
  return <GlassH {...restProps}>{children}</GlassH>;
};

Oncology.GlassV = function OncologyGlassV({
  title,
  src,
  children,
  ...restProps
}) {
  return (
    <GlassV {...restProps}>
      <h1>{title}</h1>
      <img src={`/images/${src}.png`} alt="illustration" />
      {children}
    </GlassV>
  );
};

Oncology.Cards = function OncologyCards({ children, ...restProps }) {
  return <Cards {...restProps}>{children}</Cards>;
};

Oncology.LightGlassV = function OncologyLightGlassV({
  children,
  ...restProps
}) {
  return <LightGlassV {...restProps}>{children}</LightGlassV>;
};

Oncology.GlassTitle = function OncologyGlassTitle({ children, ...restProps }) {
  return <GlassTitle {...restProps}>{children}</GlassTitle>;
};

Oncology.GlassDescription = function OncologyGlassDescription({
  children,
  ...restProps
}) {
  return <GlassDescription {...restProps}>{children}</GlassDescription>;
};

Oncology.GlassSecDescription = function OncologyGlassSecDescription({
  children,
  ...restProps
}) {
  return <GlassSecDescription {...restProps}>{children}</GlassSecDescription>;
};

Oncology.Circle = function OncologyCircle({
  top,
  right,
  width,
  height,
  background,
  children,
  ...restProps
}) {
  return (
    <Circle
      top={top}
      right={right}
      width={width}
      height={height}
      background={background}
      {...restProps}
    >
      {children}
    </Circle>
  );
};
