import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 90vh;
  background: #fcffff;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Heading = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #376092;
  margin-bottom: 1rem;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  padding: 0 2.5rem;
  height: 49px;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 1rem 0;
  cursor: pointer;

  @media (max-width: 1250px) {
    padding: 0 2.6rem;
    height: 45px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 0 2.4rem;
    height: 40px;
    font-size: 14px;
  }
`;
