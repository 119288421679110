import React from "react";
import { Redirect } from "react-router-dom";

export function ProtectedRoute({
  isLoading,
  user,
  component: Component,
  ...restProps
}) {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }
  if (!user) {
    return <Redirect to="/login" />;
  }
  return <Component {...restProps} />;
}
