import React from "react";

import { Requirement } from "./styles/passwordReqs";

export default function PasswordReqs({ passed, children, ...restProps }) {
  return (
    <Requirement passed={passed} {...restProps}>
      {children}
    </Requirement>
  );
}
