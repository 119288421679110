import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  padding: 5rem 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fcffff;
  margin: 3rem 0;

  @media (max-width: 1500px) {
    padding: 5rem 10rem;
  }

  @media (max-width: 1300px) {
    padding: 5rem 6.5rem;
  }

  @media (max-width: 1000px) {
    padding: 5rem 3.5rem;
  }

  @media (max-width: 600px) {
    padding: 5rem 1.5rem;
  }
`;

export const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  text-align: center;
  color: #376092;
  margin-bottom: 2rem;

  @media (max-width: 1500px) {
    font-size: 45px;
  }

  @media (max-width: 1300px) {
    font-size: 40px;
  }

  @media (max-width: 1000px) {
    font-size: 35px;
  }
`;

export const Image = styled.div`
  margin-bottom: 1rem;
  img {
    width: 1024px;
    height: auto;

    @media (max-width: 1500px) {
      width: 930px;
    }

    @media (max-width: 1300px) {
      width: 870px;
    }

    @media (max-width: 1000px) {
      width: 750px;
    }

    @media (max-width: 800px) {
      width: 550px;
    }

    @media (max-width: 600px) {
      width: 450px;
    }
  }
`;

export const Caption = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  text-align: center;
  color: #6d6d6d;
  margin-bottom: 7rem;
  width: 55%;

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: 800px) {
    width: 70%;
  }

  @media (max-width: 600px) {
    width: 80%;
    font-size: 11px;
    line-height: 20px;
  }
`;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const TopRow = styled.div`
  width: 100%;
  height: 65px;
  box-shadow: 0px 3px 7px rgba(57, 57, 57, 0.1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 600px) {
    padding: 0 1rem;
  }
`;

export const Row = styled.div`
  width: 100%;
  height: 96px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 1300px) {
    height: 100px;
  }

  @media (max-width: 600px) {
    padding: 0 1rem;
    height: 90px;
  }
`;

export const MainRow = styled.div`
  width: 100%;
  height: 120px;
  background: linear-gradient(86.82deg, #85a2c5 -11.25%, #376092 141.29%);
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: 600px) {
    padding: 0 1rem;
    height: 100px;
  }
`;

export const Column = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    font-size: 15px;
    line-height: 20px;
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;

export const SensitivityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    font-size: 15px;
    line-height: 20px;
    width: 33%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const UtilityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 20px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    display: none;
    font-size: 15px;
    line-height: 20px;
  }
`;

export const MainColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 22px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    font-size: 17px;
    line-height: 20px;
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

export const MainSensitivityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 22px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    font-size: 17px;
    line-height: 20px;
    width: 33%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const MainUtilityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 22px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
    line-height: 21px;
  }

  @media (max-width: 1300px) {
    font-size: 17px;
    line-height: 20px;
    display: none;
  }
`;

export const TopColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 24px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 22px;
    line-height: 24px;
  }

  @media (max-width: 1400px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1300px) {
    font-size: 19px;
    line-height: 22px;
    width: 33%;
  }

  @media (max-width: 700px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

export const TopSensitivityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 24px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 22px;
    line-height: 24px;
  }

  @media (max-width: 1400px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1300px) {
    font-size: 19px;
    line-height: 22px;
    width: 33%;
  }

  @media (max-width: 700px) {
    display: none;
  }
`;

export const TopUtilityColumn = styled.div`
  width: ${({ width }) => width};
  height: 100%;
  color: ${({ color }) => color};
  font-size: 24px;
  font-weight: ${({ fontWeight }) => fontWeight};
  text-align: ${({ textAlign }) => textAlign};
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  line-height: 25px;

  @media (max-width: 1500px) {
    font-size: 22px;
    line-height: 24px;
  }

  @media (max-width: 1400px) {
    font-size: 20px;
    line-height: 23px;
  }

  @media (max-width: 1300px) {
    font-size: 19px;
    line-height: 22px;
    display: none;
  }
`;

export const Label = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #aeaeae;
  margin-bottom: 6rem;
  width: 25%;
  align-self: start;
  padding-left: 3.5rem;

  @media (max-width: 1500px) {
    font-size: 13px;
  }

  @media (max-width: 1000px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
    width: 70%;
    padding-left: 1rem;
  }
`;

export const Sources = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SourcesTitle = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  text-align: center;
  color: #376092;
  margin-bottom: 1.3rem;

  @media (max-width: 1500px) {
    font-size: 35px;
  }

  @media (max-width: 1300px) {
    font-size: 29px;
  }

  @media (max-width: 1000px) {
    font-size: 27px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
  }
`;

export const Source = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #6d6d6d;
  margin-bottom: 2rem;
  width: 65%;

  @media (max-width: 1500px) {
    font-size: 16px;
  }

  @media (max-width: 1300px) {
    font-size: 15px;
  }

  @media (max-width: 1000px) {
    font-size: 14px;
  }

  @media (max-width: 800px) {
    width: 75%;
  }

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;
