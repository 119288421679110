import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  padding: 5rem 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 1600px) {
    padding: 5rem 10rem;
  }

  @media (max-width: 1300px) {
    padding: 5rem 7rem;
  }

  @media (max-width: 800px) {
    padding: 5rem 4rem;
  }

  @media (max-width: 500px) {
    padding: 5rem 1rem;
  }
`;

export const Title = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  color: #376092;
  margin-bottom: 4rem;

  @media (max-width: 1600px) {
    font-size: 46px;
  }

  @media (max-width: 1300px) {
    font-size: 42px;
  }

  @media (max-width: 800px) {
    font-size: 38px;
  }

  @media (max-width: 500px) {
    font-size: 34px;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

export const Image = styled.div`
  width: 19vw;
  height: 19vw;
  border-radius: 50%;
  background: linear-gradient(86.82deg, #85a2c5 -11.25%, #376092 141.29%);
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 96%;
    height: auto;
    border-radius: 50%;
  }

  @media (max-width: 800px) {
    width: 27vw;
    height: 27vw;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 60%;

  @media (max-width: 1300px) {
    width: 67%;
  }

  @media (max-width: 1000px) {
    align-items: center;
    margin-top: 3rem;
  }

  @media (max-width: 800px) {
    width: 90%;
  }

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const Description = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  color: #00303f;
  margin-bottom: 1.5rem;
  width: 85%;

  span {
    color: #6fcf97;
  }

  @media (max-width: 1600px) {
    font-size: 20px;
    line-height: 32px;
  }

  @media (max-width: 1300px) {
    font-size: 18px;
    line-height: 30px;
  }

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const SecDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  color: #6d6d6d;
  width: 80%;

  @media (max-width: 1600px) {
    font-size: 16px;
    line-height: 30px;
  }

  @media (max-width: 1300px) {
    font-size: 14px;
    line-height: 28px;
  }

  @media (max-width: 500px) {
    font-size: 13px;
    line-height: 26px;
  }

  span {
    font-weight: 600;
  }
`;
