import React from "react";

import { Container } from "./styles/logo";

export default function Logo({ width, children, ...restProps }) {
  return (
    <Container width={width} {...restProps}>
      <img width={width} src="/images/Logo.png" alt="Profile" />
    </Container>
  );
}
