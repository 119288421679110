import React, { useState, Suspense, lazy, useEffect } from "react";
import * as ROUTES from "./constants/routes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Loading } from "./components";
import Amplify from "aws-amplify";
import { COGNITO } from "./configs/aws";
import { AuthenticatedContext } from "./context/authenticated";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import { ProtectedRoute } from "./helpers/protectedRoute";
import { RedirectedRoute } from "./helpers/redirectedRoute";

const About = lazy(() => import("./pages/about"));
const ContactPage = lazy(() => import("./pages/contact"));
const Confirmation = lazy(() => import("./pages/confirmation"));
const Home = lazy(() => import("./pages/home"));
const Login = lazy(() => import("./pages/login"));
const News = lazy(() => import("./pages/news"));
const Oncology = lazy(() => import("./pages/oncology"));
const Research = lazy(() => import("./pages/research"));
const SignUp = lazy(() => import("./pages/signup"));
const Solutions = lazy(() => import("./pages/solutions"));
const UploadDocument = lazy(() => import("./pages/uploadDocument"));
const ErrorPage = lazy(() => import("./pages/error"));
const ViewDocument = lazy(() => import("./pages/viewDocument"));
const Dashboard = lazy(() => import("./pages/dashboard"));
const ResetPassword = lazy(() => import("./pages/resetPassword"));

Amplify.configure({
  aws_cognito_region: COGNITO.REGION,
  aws_user_pools_id: COGNITO.USER_POOL_ID,
  aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
});

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    let updateUser = async (authState) => {
      try {
        let user = await Auth.currentAuthenticatedUser();
        setUser(user);
        setIsLoading(false);
      } catch {
        setUser(null);
        setIsLoading(false);
      }
    };
    Hub.listen("auth", updateUser); // listen for login/signup events
    updateUser(); // check manually the first time because we won't get a Hub event
    return () => Hub.remove("auth", updateUser); // cleanup
  }, []);

  return (
    <>
      <AuthenticatedContext.Provider
        value={{ user, setUser, loggedIn, setLoggedIn }}
      >
        <Router>
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Loading />
              </div>
            }
          >
            <Switch>
              <Route path={ROUTES.HOME} exact>
                <Home />
              </Route>
              <Route path={ROUTES.ABOUT} exact>
                <About />
              </Route>
              <Route path={ROUTES.ONCOLOGY} exact>
                <Oncology />
              </Route>
              <ProtectedRoute
                user={user}
                isLoading={isLoading}
                path={ROUTES.UPLOAD}
                component={UploadDocument}
              />
              <RedirectedRoute 
                path={ROUTES.LOGIN} 
                user={user} 
                isLoading={isLoading} 
                component={Login} 
              />
              <RedirectedRoute 
                path={ROUTES.SIGNUP} 
                user={user} 
                isLoading={isLoading} 
                component={SignUp} 
              />
              <Route path={ROUTES.RESEARCH} exact>
                <Research />
              </Route>
              <Route path={ROUTES.NEWS} exact>
                <News />
              </Route>
              <Route path={ROUTES.CONTACT} exact>
                <ContactPage />
              </Route>
              <Route path={ROUTES.CONFIRMATION} exact>
                <Confirmation />
              </Route>
              <Route path={ROUTES.SOLUTIONS} exact>
                <Solutions />
              </Route>
              <Route path={ROUTES.VIEW_DOCUMENTS} exact>
                <ViewDocument />
              </Route>
              <Route path={ROUTES.DASHBOARD} exact>
                <Dashboard />
              </Route>
              <Route path={ROUTES.RESETPASSWORD} exact>
                <ResetPassword />
              </Route>
              <Route path="*">
                <ErrorPage />
              </Route>
            </Switch>
          </Suspense>
        </Router>
      </AuthenticatedContext.Provider>
    </>
  );
}
