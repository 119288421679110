import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ebf5f2;
  padding: 3rem 10rem;
  margin-top: 5rem;
  position: relative;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  @media (max-width: 1500px) {
    padding: 3rem 7rem;
  }

  @media (max-width: 900px) {
    padding: 3rem;
  }

  @media (max-width: 600px) {
    padding: 3rem 2rem;
    flex-direction: column;
  }
`;

export const Logo = styled.div`
  margin-bottom: 1.5rem;

  img {
    width: 180px;
    height: auto;

    @media (max-width: 1500px) {
      width: 150px;
    }

    @media (max-width: 900px) {
      width: 120px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 2.5rem;
  }
`;

export const Section = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  align-items: center;
`;

export const Heading = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: #255591;
  width: 75%;
  align-self: flex-start;
  margin-bottom: 1rem;

  @media (max-width: 1500px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: #6d6d6d;
  width: 60%;
  align-self: flex-start;

  @media (max-width: 1500px) {
    font-size: 12px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Label = styled.p`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #6d6d6d;
  position: absolute;
  bottom: 2.5%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  @media (max-width: 1500px) {
    font-size: 13px;
  }

  @media (max-width: 900px) {
    font-size: 12px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    text-align: center;
  }
`;

export const MenuTitle = styled.h3`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  color: #255591;
  margin-bottom: 0.8rem;

  @media (max-width: 1500px) {
    font-size: 26px;
  }

  @media (max-width: 900px) {
    font-size: 22px;
  }
`;

export const Link = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #aeaeae;
  transition: 0.5s ease all;
  margin-bottom: 0.6rem;

  @media (max-width: 1500px) {
    font-size: 14px;
  }

  @media (max-width: 900px) {
    font-size: 13px;
  }

  &:hover {
    color: #6fcf97;
  }
`;
