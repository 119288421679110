import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import {
  Container,
  Logo,
  Links,
  DropdownLink,
  Link,
  Button,
  Buttons,
  ProfileDropdown,
} from "./styles/navbar";

console.log(window.location.pathname);

export default function Navbar({ background, children, ...restProps }) {
  return (
    <Container background={background} {...restProps}>
      {children}
    </Container>
  );
}

Navbar.Logo = function NavbarLogo({
  logoBackground,
  to,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink to={to}>
      <Logo logoBackground={logoBackground} {...restProps}>
        {window.location.href === "https://staging.chimerocyte.com/" ||
        window.location.href ===
          "https://staging.chimerocyte.com" + window.location.pathname ? (
          <img src="/images/LogoStaging.png" alt="Staging Logo" />
        ) : window.location.href === "https://dev.chimerocyte.com/" ||
          window.location.href ===
            "https://dev.chimerocyte.com" + window.location.pathname ? (
          <img src="/images/LogoDevelop.png" alt="Develop Logo" />
        ) : (
          <img src="/images/Logo.png" alt="Logo" />
        )}
      </Logo>
    </ReactRouterLink>
  );
};

Navbar.Links = function NavbarLinks({ children, ...restProps }) {
  return <Links {...restProps}>{children}</Links>;
};

Navbar.Link = function NavbarLink({ to, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Link {...restProps}>{children}</Link>
    </ReactRouterLink>
  );
};

Navbar.DropdownLink = function NavbarDropdownLink({
  to,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink to={to}>
      <DropdownLink {...restProps}>
        <h3>{children}</h3>
        <i class="fas fa-chevron-down"></i>
        <div>
          <ReactRouterLink to={ROUTES.ONCOLOGY}>
            <Link>Oncology Services</Link>
          </ReactRouterLink>
          <ReactRouterLink to={ROUTES.RESEARCH}>
            <Link>Research Services</Link>
          </ReactRouterLink>
        </div>
      </DropdownLink>
    </ReactRouterLink>
  );
};

Navbar.Buttons = function NavbarButtons({ children, ...restProps }) {
  return <Buttons {...restProps}>{children}</Buttons>;
};

Navbar.ProfileDropdown = function NavbarProfileDropdown({
  onClick,
  showProfile,
  verified,
  name,
  email,
  children,
  ...restProps
}) {
  return (
    <ProfileDropdown
      onClick={onClick}
      showProfile={showProfile}
      verified={verified}
      {...restProps}
    >
      <i class="fas fa-user"></i>
      <div>
        <h3>{name}</h3>
        <h3>{email}</h3>
        <p>{verified ? "Email Verified" : "Email Not Verified"}</p>
        {children}
      </div>
    </ProfileDropdown>
  );
};

Navbar.Button = function NavbarButton({
  to,
  background,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink to={to}>
      <Button background={background} {...restProps}>
        {children}
      </Button>
    </ReactRouterLink>
  );
};
