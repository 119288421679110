import React from "react";

import {
  Container,
  Heading,
  Input,
  Button,
  SecHeader,
} from "./styles/confirmationForm";

export default function ConfirmationForm({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

ConfirmationForm.Heading = function ConfirmationFormHeading({
  children,
  ...restProps
}) {
  return <Heading {...restProps}>{children}</Heading>;
};

ConfirmationForm.SecHeader = function ConfirmationFormSecHeader({
  children,
  ...restProps
}) {
  return <SecHeader {...restProps}>{children}</SecHeader>;
};

ConfirmationForm.Input = function ConfirmationFormInput({
  value,
  setValue,
  placeholder,
  type,
  children,
  ...restProps
}) {
  return (
    <Input
      onChange={(event) => setValue(event.target.value)}
      value={value}
      placeholder={placeholder}
      type={type}
      {...restProps}
    />
  );
};

ConfirmationForm.Button = function ConfirmationFormButton({
  disabled,
  onClick,
  background,
  children,
  ...restProps
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      background={background}
      {...restProps}
    >
      {children}
    </Button>
  );
};
