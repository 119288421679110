import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Container,
  Logo,
  Section,
  Heading,
  Description,
  Link,
  Links,
  MenuTitle,
  Label,
} from "./styles/footer";

export default function Footer({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Footer.Logo = function FooterLogo({ children, ...restProps }) {
  return (
    <Logo {...restProps}>
      <img src="/images/Logo.png" alt="Logo" />
    </Logo>
  );
};

Footer.Section = function FooterSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Footer.Heading = function FooterHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Footer.Description = function FooterDescription({ children, ...restProps }) {
  return <Description {...restProps}>{children}</Description>;
};

Footer.Label = function FooterLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Footer.MenuTitle = function FooterMenu({ children, ...restProps }) {
  return <MenuTitle {...restProps}>{children}</MenuTitle>;
};

Footer.Links = function FooterLinks({ children, ...restProps }) {
  return <Links {...restProps}>{children}</Links>;
};

Footer.Link = function FooterLink({ to, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Link {...restProps}>{children}</Link>
    </ReactRouterLink>
  );
};
