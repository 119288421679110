import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 18rem;

  @media (max-width: 1600px) {
    padding: 6rem 15rem;
  }

  @media (max-width: 1450px) {
    padding: 6rem 9rem;
  }

  @media (max-width: 1100px) {
    padding: 6rem 6rem;
  }

  @media (max-width: 950px) {
    padding: 6rem 5rem;
  }

  @media (max-width: 650px) {
    padding: 6rem 3rem;
  }

  @media (max-width: 500px) {
    padding: 6rem 1.5rem;
  }
`;

export const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  text-align: center;
  color: #376092;

  @media (max-width: 1600px) {
    font-size: 47px;
  }

  @media (max-width: 1450px) {
    font-size: 43px;
  }

  @media (max-width: 1100px) {
    font-size: 38px;
  }

  @media (max-width: 950px) {
    font-size: 34px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 7rem 0;

  @media (max-width: 950px) {
    justify-content: center;
  }
`;

export const Image = styled.div`
  img {
    width: 390px;
    height: auto;

    @media (max-width: 1600px) {
      width: 375px;
    }

    @media (max-width: 1450px) {
      width: 365px;
    }

    @media (max-width: 1100px) {
      width: 345px;
    }

    @media (max-width: 950px) {
      display: none;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;

  @media (max-width: 1600px) {
    width: 68%;
  }

  @media (max-width: 1450px) {
    width: 78%;
  }

  @media (max-width: 1100px) {
    width: 90%;
  }

  @media (max-width: 950px) {
    text-align: center;
  }

  @media (max-width: 500px) {
    width: 95%;
  }
`;

export const Description = styled.h3`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 44px;
  line-height: 58px;
  text-align: ${({ textAlign }) => textAlign};
  color: #00303f;
  width: 70%;
  align-self: ${({ alignSelf }) => alignSelf};
  margin-bottom: 1rem;

  @media (max-width: 1600px) {
    font-size: 42px;
    line-height: 56px;
  }

  @media (max-width: 1450px) {
    font-size: 38px;
    line-height: 52px;
  }

  @media (max-width: 1100px) {
    font-size: 34px;
    line-height: 48px;
  }

  @media (max-width: 950px) {
    text-align: center;
    align-self: center;
    font-size: 32px;
    line-height: 46px;
  }

  @media (max-width: 500px) {
    font-size: 28px;
    line-height: 40px;
  }

  span {
    color: #6fcf97;
  }
`;

export const SecDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 33px;
  text-align: ${({ textAlign }) => textAlign};
  align-self: ${({ alignSelf }) => alignSelf};
  color: #aeaeae;
  width: 70%;

  @media (max-width: 1450px) {
    font-size: 16px;
    line-height: 31px;
    width: 75%;
  }

  @media (max-width: 1100px) {
    font-size: 15px;
    line-height: 29px;
    width: 80%;
  }

  @media (max-width: 950px) {
    text-align: center;
    align-self: center;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 28px;
  }
`;
