import React from "react";
import { Redirect } from "react-router-dom";
import * as ROUTES from "../constants/routes"

export function RedirectedRoute({
  isLoading,
  user,
  component: Component,
  ...restProps
}) {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Loading...
      </div>
    );
  }
  if (!user) {
    return <Component {...restProps} />;
  }
  return <Redirect to={ROUTES.HOME} />;
}
