import React from "react";

import {
  Container,
  Title,
  Card,
  Image,
  Name,
  Label,
  Icons,
  Icon,
  Section,
} from "./styles/team";

export default function Team({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Team.Title = function TeamTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Team.Section = function TeamSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Team.Card = function TeamCard({ children, ...restProps }) {
  return <Card {...restProps}>{children}</Card>;
};

Team.Image = function TeamImage({ src, children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src={`/images/${src}.png`} alt="Team" />
    </Image>
  );
};

Team.Name = function TeamName({ children, ...restProps }) {
  return <Name {...restProps}>{children}</Name>;
};

Team.Label = function TeamLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Team.Icons = function TeamIcons({ children, ...restProps }) {
  return <Icons {...restProps}>{children}</Icons>;
};

Team.Icon = function TeamIcon({ href, children, ...restProps }) {
  return (
    <Icon
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      alt="Link to Member's Linkedin or Email"
      {...restProps}
    >
      {children}
    </Icon>
  );
};
