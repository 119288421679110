import React from "react";

import { Container, Heading, Input, Button } from "./styles/requestCode";

export default function RequestCode({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

RequestCode.Heading = function RequestCodeHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

RequestCode.Input = function RequestCodeInput({
  value,
  setValue,
  placeholder,
  type,
  children,
  error,
  ...restProps
}) {
  return (
    <Input
      onChange={(event) => setValue(event.target.value)}
      value={value}
      placeholder={placeholder}
      type={type}
      error={error}
      {...restProps}
    />
  );
};

RequestCode.Button = function RequestCodeButton({
  disabled,
  onClick,
  background,
  children,
  ...restProps
}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      background={background}
      {...restProps}
    >
      {children}
    </Button>
  );
};
