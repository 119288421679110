import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 15rem;

  @media (max-width: 1650px) {
    padding: 5rem 8rem;
  }

  @media (max-width: 1400px) {
    padding: 5rem 4rem;
  }

  @media (max-width: 1000px) {
    padding: 5rem 2rem;
  }
`;

export const Title = styled.div`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  text-align: center;
  color: #376092;
  margin-bottom: 3rem;

  @media (max-width: 1650px) {
    font-size: 47px;
  }

  @media (max-width: 1400px) {
    font-size: 42px;
  }

  @media (max-width: 1000px) {
    font-size: 38px;
  }
`;

export const Section = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 385px;
  height: 333px;
  background: #fcffff;
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8rem 6rem;

  @media (max-width: 1650px) {
    width: 369px;
    height: 319px;
  }

  @media (max-width: 1400px) {
    width: 297px;
    height: 257px;
    margin: 6rem 4rem;
  }

  @media (max-width: 1000px) {
    width: 261px;
    height: 225px;
  }
`;

export const Image = styled.div`
  width: 230px;
  height: 230px;
  background: linear-gradient(89.5deg, #acf1ac -6.19%, #6fcf97 158.69%);
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 0 auto;
  top: -35%;
  left: 0;
  right: 0;

  @media (max-width: 1650px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 1400px) {
    width: 175px;
    height: 175px;
  }

  @media (max-width: 1000px) {
    width: 150px;
    height: 150px;
  }

  img {
    width: 95%;
    height: auto;
    border-radius: 50%;
  }
`;

export const Name = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  text-align: center;
  color: #00303f;
  margin-bottom: 1rem;

  @media (max-width: 1650px) {
    font-size: 24px;
  }

  @media (max-width: 1400px) {
    font-size: 22px;
  }

  @media (max-width: 1000px) {
    font-size: 20px;
  }
`;

export const Label = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #6fcf97;

  @media (max-width: 1650px) {
    font-size: 17px;
  }

  @media (max-width: 1400px) {
    font-size: 16px;
  }

  @media (max-width: 1000px) {
    font-size: 15px;
  }
`;

export const Icons = styled.div`
  width: 214px;
  height: 50px;
  background: #6fcf97;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 0 auto;
  bottom: -6.5%;
  left: 0;
  right: 0;

  @media (max-width: 1650px) {
    width: 190px;
    height: 44px;
  }

  @media (max-width: 1400px) {
    width: 158px;
    height: 36px;
  }

  @media (max-width: 1000px) {
    width: 140px;
    height: 32px;
  }
`;

export const Icon = styled.a`
  color: #fcffff;
  font-size: 22px;
  margin: 0 1.5rem;
  cursor: pointer;

  @media (max-width: 1650px) {
    font-size: 20px;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 1000px) {
    font-size: 16px;
  }
`;
