import React from "react";

import {
  CardText,
  Company,
  Container,
  Date,
  Heading,
  Hero,
  HeroNews,
  Label,
  NewsCard,
  NewsLogo,
  NewsSect,
  SecTitle,
  TextSect,
  TextSub,
  Title,
} from "./styles/news";

export default function News({ background, children, ...restProps }) {
  return (
    <Container background={background} {...restProps}>
      {children}
    </Container>
  );
}

News.Hero = function NewsHero({ children, ...restProps }) {
  return <Hero {...restProps}>{children}</Hero>;
};

News.TextSect = function NewsTextSect({ children, ...restProps }) {
  return <TextSect {...restProps}>{children}</TextSect>;
};

News.HeroNews = function NewsHeroNews({ children, ...restProps }) {
  return <HeroNews {...restProps}>{children}</HeroNews>;
};

News.Label = function NewsLabel({ children, ...restProps }) {
  return (
    <Label {...restProps}>
      <div></div>
      <p>{children}</p>
    </Label>
  );
};

News.Title = function NewsTitle({ link, children, ...restProps }) {
  return (
    <Title href={link} {...restProps}>
      {children}
    </Title>
  );
};

News.SecTitle = function NewsSecTitle({
  link,
  color,
  fontSize,
  children,
  ...restProps
}) {
  return (
    <a href={link}>
      <SecTitle color={color} fontSize={fontSize} {...restProps}>
        {children}
      </SecTitle>
    </a>
  );
};

News.TextSub = function NewsTextSub({ children, ...restProps }) {
  return <TextSub {...restProps}>{children}</TextSub>;
};

News.Date = function NewsDate({ color, fontSize, children, ...restProps }) {
  return (
    <Date color={color} fontSize={fontSize} {...restProps}>
      {children}
    </Date>
  );
};

News.Company = function NewsCompany({ fontSize, children, ...restProps }) {
  return (
    <Company fontSize={fontSize} {...restProps}>
      {children}
    </Company>
  );
};

News.Heading = function NewsHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

News.NewsSect = function NewsNewsSect({ children, ...restProps }) {
  return <NewsSect {...restProps}>{children}</NewsSect>;
};

News.NewsCard = function NewsNewsCard({ children, ...restProps }) {
  return <NewsCard {...restProps}>{children}</NewsCard>;
};

News.CardText = function NewsCardText({ margin, children, ...restProps }) {
  return (
    <CardText margin={margin} {...restProps}>
      {children}
    </CardText>
  );
};

News.NewsLogo = function NewsNewsLogo({ src, children, ...restProps }) {
  return (
    <NewsLogo src={`/images/${src}.png`} alt={src} {...restProps}></NewsLogo>
  );
};
