import React from "react";

import { Container, Text, Profile, Image } from "./styles/quote";

export default function Quote({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Quote.Text = function QuoteText({ children, ...restProps }) {
  return (
    <Text {...restProps}>
      <h3>
        “We’re trying to hone in on treatments. I would like to help{" "}
        <span>alleviate suffering</span> in some way.”
      </h3>
      <p>-Dr. J. Lee Nelson</p>
    </Text>
  );
};

Quote.Profile = function QuoteProfile({
  background,
  src,
  children,
  ...restProps
}) {
  return (
    <Profile background={background} {...restProps}>
      <img src={`/images/${src}.png`} alt="Profile" />
    </Profile>
  );
};

Quote.Image = function QuoteImage({
  width,
  src,
  top,
  left,
  right,
  children,
  ...restProps
}) {
  return (
    <Image width={width} top={top} left={left} right={right} {...restProps}>
      <img src={`/images/${src}.png`} alt="Parallax" />
    </Image>
  );
};
