import React from "react";

import { Container, Hero, Services, Service } from "./styles/solutions";

export default function Solutions({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Solutions.Hero = function SolutionsHero({ children, ...restProps }) {
  return <Hero {...restProps}>{children}</Hero>;
};

Solutions.Services = function SolutionsServices({ children, ...restProps }) {
  return (
    <Services {...restProps}>
      <h1>Services</h1>
      <p>
        Chimerocyte offers oncology and research services to meet any chimerism
        monitoring needs
      </p>
    </Services>
  );
};

Solutions.Service = function SolutionsService({
  title,
  description,
  src,
  children,
  ...restProps
}) {
  return (
    <Service {...restProps}>
      <img src={`/images/${src}.png`} alt={src} />
      <h3>{title}</h3>
      <p>{description}</p>
      {children}
    </Service>
  );
};
