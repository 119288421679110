import React from "react";

import { Container, Heading, Button } from "./styles/successMessage";
import { Link as ReactRouterLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

export default function SuccessMessage({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

SuccessMessage.Heading = function SuccessMessageHeading({
  children,
  ...restProps
}) {
  return <Heading {...restProps}>{children}</Heading>;
};

SuccessMessage.Button = function SuccessMessageButton({
  disabled,
  onClick,
  background,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink to={ROUTES.LOGIN}>
      <Button
        disabled={disabled}
        onClick={onClick}
        background={background}
        {...restProps}
      >
        {children}
      </Button>
    </ReactRouterLink>
  );
};
