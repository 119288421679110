import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MapArea = styled.div`
  position: relative;
  width: 100%;
  height: 85vh;
`;

export const Hero = styled.div`
  width: 379px;
  background: #fcffff;
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 22%;
  left: 70%;
  z-index: 5;
  padding: 2rem 1.5rem;

  @media (max-width: 1350px) {
    left: 67%;
    width: 359px;
  }

  @media (max-width: 1200px) {
    left: 5%;
    width: 339px;
  }

  @media (max-width: 700px) {
    width: 319px;
    padding: 2rem 0.5rem;
    top: 62%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    width: 289px;
    padding: 1.4rem;
    top: 68%;
  }

  i {
    font-size: 80px;
    color: #6fcf97;
    margin-bottom: 2rem;

    @media (max-width: 1350px) {
      font-size: 65px;
    }

    @media (max-width: 1200px) {
      font-size: 55px;
    }

    @media (max-width: 700px) {
      width: 40px;
      margin-bottom: 1rem;
    }

    @media (max-width: 400px) {
      width: 30px;
      margin-bottom: 0.8rem;
    }
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 40px;
    color: #00303f;
    margin-bottom: 2rem;

    @media (max-width: 1350px) {
      font-size: 22px;
    }

    @media (max-width: 1200px) {
      font-size: 20px;
      line-height: 35px;
    }

    @media (max-width: 700px) {
      font-size: 18px;
      margin-bottom: 1rem;
      line-height: 25px;
    }

    @media (max-width: 400px) {
      font-size: 16px;
      margin-bottom: 0.8rem;
      line-height: 23px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #6d6d6d0;
    margin-bottom: 2rem;

    @media (max-width: 1350px) {
      font-size: 14px;
    }

    @media (max-width: 1200px) {
      font-size: 13px;
    }

    @media (max-width: 700px) {
      font-size: 12px;
      margin-bottom: 1rem;
    }

    @media (max-width: 400px) {
      font-size: 11px;
      margin-bottom: 0.8rem;
    }
  }
`;

export const DirectionsBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  padding: 1rem 3rem;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 0 0.5rem;
  cursor: pointer;

  @media (max-width: 1350px) {
    font-size: 16px;
    padding: 0.8rem 2.5rem;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    padding: 0.6rem 2.2rem;
    margin: 0 0.3rem;
  }

  @media (max-width: 400px) {
    font-size: 13px;
    padding: 0.5rem 2.1rem;
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 100vh;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcffff;
  margin-bottom: 10rem;
  margin-top: 2rem;
  position: relative;
`;

export const Inputs = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-right: 2.5rem;

  @media (max-width: 1100px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }

  @media (max-width: 650px) {
    padding: 0 0.8rem;
  }

  @media (max-width: 450px) {
    padding: 0 0.3rem;
  }
`;

export const NameInputs = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

export const Input = styled.div`
  width: 47%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  @media (max-width: 450px) {
    width: 100%;
  }

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2b0030;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @media (max-width: 1500px) {
      font-size: 14px;
    }

    @media (max-width: 650px) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    height: 59px;
    background: linear-gradient(
      90deg,
      #e9f1f4 -16.81%,
      rgba(233, 244, 242, 0.3) 100%
    );
    box-shadow: 0px 5px 25px rgba(57, 57, 57, 0.02);
    border-radius: 100px;
    outline: none;
    border: 1.3px solid rgba(202, 202, 202, 0.1);
    padding: 0 2rem;
    transition: 0.5s ease all;

    &:hover,
    &:focus {
      background: rgba(252, 252, 248, 1);
    }

    @media (max-width: 1500px) {
      height: 50px;
      font-size: 14px;
    }

    @media (max-width: 650px) {
      height: 40px;
      font-size: 12px;
    }
  }
`;

export const FullInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2b0030;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @media (max-width: 1500px) {
      font-size: 14px;
    }

    @media (max-width: 650px) {
      font-size: 12px;
    }
  }

  input {
    width: 100%;
    height: 59px;
    background: linear-gradient(
      90deg,
      #e9f1f4 -16.81%,
      rgba(233, 244, 242, 0.3) 100%
    );
    box-shadow: 0px 5px 25px rgba(57, 57, 57, 0.02);
    border-radius: 100px;
    outline: none;
    border: 1.3px solid rgba(202, 202, 202, 0.1);
    padding: 0 2rem;
    transition: 0.5s ease all;

    &:hover,
    &:focus {
      background: rgba(252, 252, 248, 1);
    }

    @media (max-width: 1500px) {
      height: 50px;
      font-size: 14px;
    }

    @media (max-width: 650px) {
      height: 42px;
      font-size: 12px;
    }
  }
`;

export const Heading = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  color: #4d7c8a;
  margin-bottom: 1.5rem;

  @media (max-width: 1500px) {
    font-size: 44px;
  }

  @media (max-width: 650px) {
    font-size: 34px;
  }
`;

export const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2rem 0;

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2b0030;
    margin-bottom: 0.5rem;
    text-transform: uppercase;

    @media (max-width: 1500px) {
      font-size: 14px;
    }

    @media (max-width: 650px) {
      font-size: 12px;
    }
  }

  textarea {
    width: 100%;
    height: 354px;
    background: linear-gradient(
      90deg,
      #e9f1f4 -16.81%,
      rgba(233, 244, 242, 0.3) 100%
    );
    box-shadow: 0px 5px 25px rgba(57, 57, 57, 0.02);
    border-radius: 25px;
    resize: none;
    outline: none;
    border: 1.3px solid rgba(202, 202, 202, 0.1);
    padding: 1.5rem 2rem;
    transition: 0.5s ease all;

    &:hover,
    &:focus {
      background: rgba(252, 252, 248, 1);
    }

    @media (max-width: 1500px) {
      height: 344px;
      font-size: 14px;
    }

    @media (max-width: 650px) {
      height: 300px;
      font-size: 12px;
    }
  }
`;
