import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fcffff;
`;

export const Hero = styled.div`
  width: 100%;
  background: linear-gradient(
      25.21deg,
      rgba(74, 112, 123, 0.85) 2.83%,
      rgba(43, 48, 63, 0.85) 67.99%
    ),
    url("/images/SolutionsBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-bottom: 8rem;
  padding: 10rem 10rem;

  @media (max-width: 1500px) {
    padding: 10rem 5rem;
  }

  @media (max-width: 1250px) {
    padding: 10rem 3rem;
  }

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 500px) {
    padding: 10rem 1.5rem;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 3rem;

  @media (max-width: 1100px) {
    text-align: center;
    margin-right: 0;
    align-items: center;
    margin-bottom: 2rem;
  }

  h1 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    color: #acf1ac;
    margin-bottom: 2rem;

    @media (max-width: 1500px) {
      font-size: 50px;
    }

    @media (max-width: 1250px) {
      font-size: 44px;
    }

    @media (max-width: 1100px) {
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 38px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #fcffff;
    line-height: 25px;
    width: 65%;

    @media (max-width: 1500px) {
      font-size: 17px;
    }

    @media (max-width: 1250px) {
      font-size: 16px;
    }

    @media (max-width: 1100px) {
      text-align: center;
    }

    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
`;

export const Service = styled.div`
  padding: 5rem 0rem;
  background: rgba(43, 48, 63, 0.35);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;

  @media (max-width: 1100px) {
    margin: 2rem;
    padding: 4rem 0;
  }

  img {
    height: 200px;
    width: auto;
    margin-bottom: 2rem;

    @media (max-width: 1500px) {
      height: 190px;
    }

    @media (max-width: 1250px) {
      height: 180px;
    }

    @media (max-width: 500px) {
      height: 170px;
    }
  }

  h3 {
    text-align: center;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    color: #acf1ac;
    margin-bottom: 1rem;

    @media (max-width: 1500px) {
      font-size: 22px;
    }

    @media (max-width: 1250px) {
      font-size: 20px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #fcffff;
    margin-bottom: 2.5rem;
    width: 65%;

    @media (max-width: 1500px) {
      font-size: 15px;
    }

    @media (max-width: 1250px) {
      font-size: 14px;
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }
  }
`;
