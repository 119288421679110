export const HOME = "/";
export const ABOUT = "/about";
export const LOGIN = "/login";
export const ONCOLOGY = "/oncology";
export const UPLOAD = "/upload";
export const SIGNUP = "/signup";
export const RESEARCH = "/research";
export const NEWS = "/news";
export const CONTACT = "/contact";
export const CONFIRMATION = "/confirmation";
export const SOLUTIONS = "/solutions";
export const ERROR = "*";
export const VIEW_DOCUMENTS = "/view";
export const DASHBOARD = "/dashboard";
export const RESETPASSWORD = "/reset";
