import React from "react";

import {
  Caption,
  Chart,
  Column,
  Container,
  Image,
  Label,
  MainColumn,
  MainRow,
  MainUtilityColumn,
  MainSensitivityColumn,
  Row,
  SensitivityColumn,
  Sources,
  SourcesTitle,
  Source,
  Title,
  TopColumn,
  TopRow,
  TopSensitivityColumn,
  TopUtilityColumn,
  UtilityColumn,
} from "./styles/table";

export default function Table({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Table.Title = function TableTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Table.Image = function TableImage({ children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src="/images/Chart.png" alt="Table" />
    </Image>
  );
};

Table.Caption = function TableCaption({ children, ...restProps }) {
  return <Caption {...restProps}>{children}</Caption>;
};

Table.Chart = function TableChart({ children, ...restProps }) {
  return <Chart {...restProps}>{children}</Chart>;
};

Table.TopRow = function TableTopRow({ children, ...restProps }) {
  return (
    <TopRow {...restProps}>
      <TopColumn color="#6D6D6D" fontSize="24px" fontWeight="600" width="20%">
        Detection
      </TopColumn>
      <TopUtilityColumn
        color="#6D6D6D"
        fontSize="24px"
        fontWeight="600"
        width="40%"
      >
        Utility
      </TopUtilityColumn>
      <TopColumn color="#6D6D6D" fontSize="24px" fontWeight="600" width="20%">
        Informativeness
      </TopColumn>
      <TopSensitivityColumn
        color="#6D6D6D"
        fontSize="24px"
        fontWeight="600"
        width="20%"
      >
        Sensitivity
      </TopSensitivityColumn>
    </TopRow>
  );
};

Table.Row = function TableRow({
  informativeness,
  sensitivity,
  utility,
  detection,
  children,
  ...restProps
}) {
  return (
    <Row {...restProps}>
      <Column color="#7F9C96" fontSize="20px" fontWeight="600" width="20%">
        {detection}
      </Column>
      <UtilityColumn
        color="#AEAEAE"
        fontSize="20px"
        fontWeight="500"
        width="40%"
      >
        {utility}
      </UtilityColumn>
      <Column color="#4D7C8A" fontSize="20px" fontWeight="500" width="20%">
        {informativeness}
      </Column>
      <SensitivityColumn
        color="#AEAEAE"
        fontSize="20px"
        fontWeight="500"
        width="20%"
      >
        {sensitivity}
      </SensitivityColumn>
    </Row>
  );
};

Table.MainRow = function TableMainRow({
  detection,
  utility,
  informativeness,
  sensitivity,
  children,
  ...restProps
}) {
  return (
    <MainRow {...restProps}>
      <MainColumn color="#B6FCB6" fontSize="22px" fontWeight="700" width="20%">
        {detection}
      </MainColumn>
      <MainUtilityColumn
        color="#FFFFFC"
        fontSize="22px"
        fontWeight="500"
        width="40%"
      >
        {utility}
      </MainUtilityColumn>
      <MainColumn
        color="#FFFFFC"
        fontSize="22px"
        fontWeight="500"
        width="20%"
        textAlign="center"
      >
        {informativeness}
      </MainColumn>
      <MainSensitivityColumn
        color="#FFFFFC"
        fontSize="22px"
        fontWeight="500"
        width="20%"
        textAlign="center"
      >
        {sensitivity}
      </MainSensitivityColumn>
    </MainRow>
  );
};

Table.Label = function TableLabel({ children, ...restProps }) {
  return <Label {...restProps}>{children}</Label>;
};

Table.Sources = function TableSources({ children, ...restProps }) {
  return <Sources {...restProps}>{children}</Sources>;
};

Table.SourcesTitle = function TableSourcesTitle({ children, ...restProps }) {
  return <SourcesTitle {...restProps}>{children}</SourcesTitle>;
};

Table.Source = function TableSource({ children, ...restProps }) {
  return <Source {...restProps}>{children}</Source>;
};
