import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Container,
  Section,
  Buttons,
  Button,
  Title,
  Description,
  Particles,
} from "./styles/hero";

export default function Hero({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Hero.Section = function HeroSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Hero.Particles = function HeroParticles({ children, ...restProps }) {
  return <Particles {...restProps}>{children}</Particles>;
};

Hero.Title = function HeroTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Hero.Description = function HeroDescription({ children, ...restProps }) {
  return <Description {...restProps}>{children}</Description>;
};

Hero.Buttons = function HeroButtons({ children, ...restProps }) {
  return <Buttons {...restProps}>{children}</Buttons>;
};

Hero.Button = function HeroButton({ to, background, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Button background={background} {...restProps}>
        {children}
      </Button>
    </ReactRouterLink>
  );
};
