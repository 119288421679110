import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fcffff;
`;

export const GreenBg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(179.86deg, #5b9279 0.12%, #c2dadf 99.89%);
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-bottom-left-radius: 250px;
  border-bottom-right-radius: 250px;
  padding: 5rem 10rem;

  @media (max-width: 1550px) {
    padding: 5rem 7rem;
  }

  @media (max-width: 1300px) {
    padding: 5rem 3rem;
    border-bottom-left-radius: 200px;
    border-bottom-right-radius: 200px;
  }

  @media (max-width: 850px) {
    padding: 5rem 1.5rem;
  }

  @media (max-width: 700px) {
    border-bottom-left-radius: 150px;
    border-bottom-right-radius: 150px;
  }

  @media (max-width: 500px) {
    padding: 5rem 0.5rem;
    border-bottom-left-radius: 70px;
    border-bottom-right-radius: 70px;
  }
`;

export const Hero = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10rem;
  padding: 0 3rem;

  @media (max-width: 1100px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 600px) {
    padding: 0 1.5rem;
  }
`;

export const Heading = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  color: #acf1ac;
  margin-bottom: 1.5rem;

  @media (max-width: 1550px) {
    font-size: 46px;
  }

  @media (max-width: 850px) {
    font-size: 40px;
    text-align: center;
    line-height: 50px;
  }

  @media (max-width: 500px) {
    font-size: 30px;
    line-height: 40px;
  }
`;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem 0;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3rem;

  img {
    width: 550px;
    height: auto;

    @media (max-width: 1400px) {
      width: 450px;
      height: auto;
    }

    @media (max-width: 1300px) {
      width: 350px;
      height: auto;
    }

    @media (max-width: 600px) {
      width: 250px;
      height: auto;
    }
  }

  @media (max-width: 1100px) {
    margin: 3.5rem 0;
  }
`;

export const CardImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0;

  img {
    width: auto;
    height: 300px;

    @media (max-width: 1500px) {
      height: 225px;
    }

    @media (max-width: 1200px) {
      height: 190px;
    }

    @media (max-width: 500px) {
      height: 160px;
    }

    @media (max-width: 350px) {
      height: 140px;
    }
  }
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
  color: #fcfcf9;
  width: 60%;
  margin-bottom: 1rem;

  @media (max-width: 1550px) {
    font-size: 14px;
    line-height: 30px;
    width: 80%;
  }

  @media (max-width: 1100px) {
    text-align: center;
    margin: 2rem 0;
  }

  @media (max-width: 850px) {
    font-size: 13px;
    line-height: 28px;
    width: 80%;
  }

  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 26px;
    width: 95%;
  }
`;

export const GettingStarted = styled.div`
  width: 70%;
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(92.45deg, #5b9279 2.05%, #c2dadf 97.95%);
  border-radius: 40px;
  margin: 5rem 0;

  @media (max-width: 1550px) {
    width: 80%;
    padding: 3rem 0;
  }

  @media (max-width: 1000px) {
    width: 90%;
    padding: 2.5rem 0;
  }

  h2 {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 0.5rem;

    @media (max-width: 1550px) {
      font-size: 30px;
    }

    @media (max-width: 1000px) {
      font-size: 26px;
    }

    @media (max-width: 400px) {
      font-size: 24px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    width: 45%;
    color: #f4f6f9;
    margin-bottom: 1.8rem;

    @media (max-width: 1550px) {
      font-size: 15px;
      line-height: 27px;
      width: 55%;
    }

    @media (max-width: 1000px) {
      font-size: 14px;
      line-height: 25px;
      width: 65%;
    }

    @media (max-width: 400px) {
      font-size: 13px;
      line-height: 24px;
      width: 75%;
    }
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5rem;

  @media (max-width: 1100px) {
    align-items: center;
    margin: 0;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  position: relative;
  padding: 0 8rem;
`;

export const LightGlassV = styled.div`
  position: relative;
  z-index: 3;
  width: 483px;
  height: 1159px;
  background: rgba(21, 21, 21, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 40px;
  border: 1.3px solid rgba(255, 255, 255, 0.18);
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 4rem;
  padding: 4rem 2.5rem;

  @media (max-width: 1500px) {
    width: 427px;
    height: 1043px;
    margin: 2rem;
  }

  @media (max-width: 600px) {
    width: 397px;
    height: 951px;
  }

  @media (max-width: 450px) {
    width: 335px;
    height: 803px;
    padding: 1.5rem;
  }
`;

export const GlassTitle = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 52px;
  color: rgba(21, 21, 21, 0.5);
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 1500px) {
    font-size: 30px;
    line-height: 48px;
  }

  @media (max-width: 600px) {
    font-size: 25px;
    line-height: 42px;
  }

  @media (max-width: 450px) {
    font-size: 20px;
    line-height: 36px;
  }
`;

export const GlassDescription = styled.h3`
  font-family: Inter;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #b1b4c4;
  margin-bottom: 2rem;
  text-align: left;

  @media (max-width: 1500px) {
    font-size: 17px;
    line-height: 27px;
  }

  @media (max-width: 600px) {
    font-size: 15px;
    line-height: 24px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
    line-height: 21px;
  }
`;

export const GlassSecDescription = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #adafb9;
  margin-bottom: 1.5rem;
  text-align: left;
  width: 90%;

  @media (max-width: 1500px) {
    font-size: 16px;
    line-height: 27px;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 450px) {
    width: 75%;
  }
`;

export const Circle = styled.div`
  position: absolute;
  width: ${({ width }) => width};
  height: ${({ width }) => width};
  background: ${({ background }) => background};
  border-radius: 50%;
  top: ${({ top }) => top};
  right: ${({ right }) => right};

  @media (max-width: 1500px) {
    width: 190px;
    height: 190px;
  }

  @media (max-width: 1200px) {
    width: 120px;
    height: 120px;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;
