import React from "react";

import { Container, Card, Icon } from "./styles/cards";

export default function Cards({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Cards.Card = function CardsCard({
  src,
  description,
  heading,
  children,
  ...restProps
}) {
  return (
    <Card {...restProps}>
      <Icon>
        <i className="fas fa-check"></i>
      </Icon>
      <h2>{heading}</h2>
      <img src={`/images/${src}.png`} alt="illustration" />
      <p>{description}</p>
    </Card>
  );
};
