import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fcffff;
`;

export const Hero = styled.div`
  width: 100%;
  ${"" /* height: 80vh; */}
  background: linear-gradient(
      25.21deg,
      rgba(74, 112, 123, 0.85) 2.83%,
      rgba(43, 48, 63, 0.85) 67.99%
    ),
    url("/images/NewsBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  margin-bottom: 8rem;
  padding: 6rem 2rem;

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const TextSect = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 60%;

  @media (max-width: 850px) {
    width: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
  }
`;

export const SecTitle = styled.h3`
  color: ${({ color }) => color};
  font-size: 20px;
  line-height: 25px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  margin-bottom: 1.5rem;

  @media (max-width: 1500px) {
    font-size: 18px;
  }

  @media (max-width: 1000px) {
    font-size: 16px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const HeroNews = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem 2rem;
  background: rgba(43, 48, 63, 0.3);
  border-radius: 40px;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;

  div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: linear-gradient(89.5deg, #acf1ac -6.19%, #6fcf97 158.69%);
    margin-right: 0.3rem;
  }

  p {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #fcfcf9;
  }
`;

export const Title = styled.a`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 74px;
  width: 70%;
  color: #acf1ac;
  margin-bottom: 1.5rem;
  width: 50%;

  @media (max-width: 1500px) {
    font-size: 42px;
    line-height: 64px;
    width: 70%;
  }

  @media (max-width: 1000px) {
    font-size: 32px;
    line-height: 54px;
  }

  @media (max-width: 850px) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 95%;
    font-size: 26px;
    line-height: 34px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const TextSub = styled.div`
  display: flex;
`;

export const Date = styled.p`
  font-size: 13px;
  color: ${({ color }) => color};
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  margin-right: 2.5rem;
  line-height: 18px;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const Company = styled.p`
  font-size: 13px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  color: #56ccf2;
  line-height: 18px;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`;

export const NewsSect = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10rem;
`;

export const Heading = styled.h2`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  color: #255591;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 1500px) {
    font-size: 42px;
  }

  @media (max-width: 1000px) {
    font-size: 32px;
  }
`;

export const NewsCard = styled.div`
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  width: 100%;
`;

export const NewsLogo = styled.img`
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 30px;

  @media (max-width: 1500px) {
    width: 110px;
    height: 110px;
  }

  @media (max-width: 1000px) {
    width: 90px;
    height: 90px;
  }
`;
