import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 15rem;
  background-image: url("/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1650px) {
    justify-content: center;
    text-align: center;
  }

  @media (max-width: 1300px) {
    padding: 5rem 5rem;
  }

  @media (max-width: 650px) {
    padding: 5rem 2rem;
  }

  @media (max-width: 550px) {
    padding: 5rem 1rem;
  }
`;

export const Image = styled.div`
  img {
    width: 41.666666666666664vw;
    height: auto;

    @media (max-width: 900px) {
      width: 550px;
    }

    @media (max-width: 650px) {
      width: 450px;
    }

    @media (max-width: 550px) {
      width: 350px;
    }
  }

  @media (max-width: 1650px) {
    margin-bottom: 2rem;
  }
`;

export const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 58px;
  line-height: 70px;
  color: #376092;
  text-align: right;
  width: 20%;

  @media (max-width: 1650px) {
    text-align: center;
    width: 50%;
  }

  @media (max-width: 1300px) {
    width: 70%;
    font-size: 46px;
    line-height: 60px;
  }

  @media (max-width: 900px) {
    font-size: 36px;
    line-height: 50px;
    width: 90%;
  }

  @media (max-width: 650px) {
    font-size: 26px;
    line-height: 40px;
  }

  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
