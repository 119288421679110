import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 89vh;
  background-image: url("/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  @media (max-width: 1000px) {
    align-items: center;
  }
`;

export const Particles = styled.div`
  #tsparticles {
    width: 43%;
    height: 85%;
    position: absolute;
    margin-left: 50%;
    overflow: hidden;

    @media (max-width: 1000px) {
      margin-left: 0;
      width: 100%;
    }
  }

  @media (max-width: 1000px) {
    ${"" /* margin-left: 0; */}
    width: 100%;
    height: auto;
  }
`;

export const Section = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 6rem;

  @media (max-width: 1150px) {
    padding: 0 4rem;
  }

  @media (max-width: 1000px) {
    align-items: center;
    z-index: 5;
  }

  @media (max-width: 500px) {
    padding: 0 2rem;
  }
`;

export const Title = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 65px;
  color: #376092;
  margin-bottom: 2rem;
  width: 40%;

  @media (max-width: 1350px) {
    line-height: 58px;
    font-size: 46px;
  }

  @media (max-width: 1000px) {
    font-size: 42px;
    line-height: 51px;
    text-align: center;
    width: 60%;
  }

  @media (max-width: 700px) {
    width: 75%;
    font-size: 36px;
  }

  @media (max-width: 500px) {
    font-size: 32px;
    width: 100%;
    line-height: 42px;
  }
`;

export const Description = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 30px;
  color: #6d6d6d;
  margin-bottom: 2rem;
  width: 35%;

  @media (max-width: 1350px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media (max-width: 1000px) {
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    width: 45%;
  }

  @media (max-width: 700px) {
    width: 75%;
    font-size: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 500px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  width: 185px;
  height: 53px;
  font-size: 17px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin-right: 1rem;
  cursor: pointer;

  @media (max-width: 1350px) {
    width: 149px;
    height: 43px;
    font-size: 15px;
  }

  @media (max-width: 1000px) {
    margin: 0 1rem;
  }

  @media (max-width: 500px) {
    margin: 0.8rem 0.5rem;
    width: 129px;
    height: 38px;
    font-size: 13px;
  }
`;
