import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

import {
  Container,
  Bar,
  Tab,
  Main,
  Row,
  TopRow,
  Value,
  TopValue,
  FirstUpload,
} from "./styles/dashboard";

export default function Dashboard({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Dashboard.Bar = function DashboardBar({ children, ...restProps }) {
  return <Bar {...restProps}>{children}</Bar>;
};

Dashboard.Tab = function DashboardTab({
  to,
  label,
  color,
  icon,
  children,
  ...restProps
}) {
  return (
    <ReactRouterLink to={to}>
      <Tab color={color} class={icon} {...restProps}>
        <div>
          <i color={color} class={icon}></i>
        </div>
        <p>{label}</p>
      </Tab>
    </ReactRouterLink>
  );
};

Dashboard.Main = function DashboardMain({ children, ...restProps }) {
  return <Main {...restProps}>{children}</Main>;
};

Dashboard.TopRow = function DashboardTopRow({ children, ...restProps }) {
  return (
    <TopRow {...restProps}>
      <TopValue>
        <p>Name</p>
      </TopValue>
      <TopValue>
        <p>Uploaded</p>
      </TopValue>
      <TopValue>
        <p>Last Modified</p>
      </TopValue>
    </TopRow>
  );
};

Dashboard.FirstUpload = function DashboardFirstUpload({
  children,
  ...restProps
}) {
  return (
    <FirstUpload {...restProps}>
      <div>
        <h3>Click here to upload your first document:</h3>
        {children}
      </div>
    </FirstUpload>
  );
};

Dashboard.Row = function DashboardRow({
  last_modified,
  date_uploaded,
  document_name,
  children,
  ...restProps
}) {
  return (
    <Row {...restProps}>
      <Value color="#4F4F4F">
        <p>{document_name}</p>
      </Value>
      <Value color="#828282">
        <p>{date_uploaded}</p>
      </Value>
      <Value color="#828282">
        <p>{last_modified}</p>
      </Value>
    </Row>
  );
};
