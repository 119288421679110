import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;
  background: #fcffff;
  margin-bottom: 10rem;

  @media (max-width: 1100px) {
    padding: 1.5rem 0;
  }
`;

export const Visual = styled.div`
  width: 40%;
  height: 100vh;
  position: relative;
  background-image: url("/images/background3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 2rem;

  div:nth-child(4) {
    @media (max-width: 1600px) {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  padding: 0 3rem;
  height: 48px;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 0 0.5rem;
  cursor: pointer;

  @media (max-width: 1250px) {
    padding: 0 2.6rem;
    height: 45px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 0 2.4rem;
    height: 40px;
    font-size: 14px;
  }
`;

export const Section = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-left: ${({ paddingLeft }) => paddingLeft};

  @media (max-width: 1100px) {
    padding: 0 3rem;
    width: 100%;
  }

  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;
  }
`;

export const Picture = styled.div`
  background: linear-gradient(
    123.87deg,
    #fcfcf9 16.75%,
    rgba(252, 252, 249, 0.5) 97.63%
  );
  border: 1px solid rgba(255, 255, 255, 0.18);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  box-sizing: border-box;
  box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);
  border-radius: 30px;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  z-index: 3;

  img {
    height: 10vw;
    width: auto;

    @media (max-width: 1350px) {
      height: 13vw;
    }
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 5rem;

  @media (max-width: 1250px) {
    padding: 0 2.5rem;
  }

  @media (max-width: 1100px) {
    padding: 0 10rem;
  }

  @media (max-width: 850px) {
    padding: 0 5rem;
  }

  @media (max-width: 750px) {
    padding: 0 2.5rem;
  }
`;

export const Input = styled.input`
  background: linear-gradient(
    90deg,
    #e9f1f4 -16.81%,
    rgba(233, 244, 242, 0.3) 100%
  );
  box-shadow: 0px 5px 25px rgba(57, 57, 57, 0.02);
  border-radius: 100px;
  width: 100%;
  height: 70px;
  margin: 2rem 0;
  outline: none;
  ${({ error }) =>
    error
      ? `
    border: 1.3px solid rgba(246, 36, 89, 1);
  `
      : `border: 1.3px solid rgba(202, 202, 202, 0.1);`}

  padding: 0 2rem;
  transition: 0.5s ease all;

  &:hover,
  &:focus {
    background: rgba(252, 252, 248, 1);
  }

  @media (max-width: 1500px) {
    height: 60px;
    font-size: 14px;
  }

  @media (max-width: 750px) {
    height: 55px;
    font-size: 13px;
  }
`;

export const Message = styled.div`
  margin-top: 5rem;
  display: flex;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000000;

    span {
      color: #6fcf97;
      transition: 0.5s ease all;

      &:hover {
        text-decoration: underline;
      }
    }

    @media (max-width: 1500px) {
      font-size: 16px;
    }

    @media (max-width: 750px) {
      font-size: 14px;
    }
  }
`;

export const Links = styled.div`
  width: 80%;
  height: 63px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fffffc;
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-radius: 40px;
  padding: 0 0.5rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  position: relative;

  @media (max-width: 1500px) {
    height: 58px;
    width: 85%;
    padding: 0 0.8rem;
  }

  @media (max-width: 1100px) {
    width: 100%;
    padding: 0;
    background: transparent;
    box-shadow: none;
  }
`;

export const Link = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #376092;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    color: #6fcf97;
  }

  @media (max-width: 1600px) {
    font-size: 15px;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Image = styled.div`
  img {
    width: 200px;
    height: auto;

    @media (max-width: 1500px) {
      width: 180px;
    }
  }
`;

export const DropdownLink = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  cursor: pointer;

  @media (max-width: 1100px) {
    display: none;
  }

  h3 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #376092;
    transition: all 0.5s ease;
    margin-right: 0.5rem;

    &:hover {
      color: #6fcf97;
    }

    @media (max-width: 1600px) {
      font-size: 15px;
    }

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  i {
    font-size: 13px;
    color: #1b4079;
    transition: all 0.5s ease;

    &:hover {
      color: #c2d68a;
    }

    @media (max-width: 1400px) {
      font-size: 12px;
    }
  }

  div {
    width: 204px;
    height: 100px;
    background: #fffffc;
    display: none;
    position: absolute;
    ${"" /* margin-top: 0.3rem; */}
    top: 110%;
    z-index: 3;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s ease display;
    box-shadow: 0px 15px 60px rgba(57, 57, 57, 0.1);
    border-radius: 15px;
    padding: 0.6rem 0;
  }

  &:hover {
    div {
      display: flex;
    }
  }
`;
