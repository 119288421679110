import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  background-image: url("../../images/UploadBackground.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-top: 0.5rem;
  padding-bottom: 5rem;
`;

export const Bar = styled.div`
  width: 85%;
  border-radius: 40px;
  height: 170px;
  background: rgba(255, 255, 252, 0.55);
  box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin: 2rem 0;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 2rem;
  transition: 0.7s ease all;

  &:hover {
    p {
      color: ${({ color }) => color};
    }
  }

  div {
    width: 90px;
    height: 90px;
    background: rgba(255, 255, 252, 0.85);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.8rem;
    box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);

    i {
      font-size: 36px;
      color: ${({ color }) => color};
    }
  }

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
  }
`;

export const Main = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 252, 0.7);
  box-shadow: 0px 10px 60px rgba(57, 57, 57, 0.1);
  border-radius: 40px;
  padding: 1rem 4.5rem;
`;

export const TopRow = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
`;

export const TopValue = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    color: #6d6d6d;
  }
`;

export const FirstUpload = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      color: #828282;
      margin-bottom: 2rem;
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
`;

export const Value = styled.div`
  width: 33%;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: ${({ color }) => color};
  }
`;
