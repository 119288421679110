import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fcffff;
  margin: 2rem 0;

  img {
    width: ${({ width }) => width};
    height: auto;
  }
`;
