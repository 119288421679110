import React from "react";

import {
  Container,
  Title,
  Image,
  Description,
  SecDescription,
  Text,
  Section,
} from "./styles/story";

export default function Story({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Story.Title = function StoryTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Story.Section = function StorySection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Story.Image = function StoryImage({ children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src="/images/Story.png" alt="story" />
    </Image>
  );
};

Story.Text = function StoryText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Story.Description = function StoryDescription({ children, ...restProps }) {
  return <Description {...restProps}>{children}</Description>;
};

Story.SecDescription = function StorySecDescription({
  children,
  ...restProps
}) {
  return <SecDescription {...restProps}>{children}</SecDescription>;
};
