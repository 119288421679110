import React from "react";

import {
  CardImage,
  Cards,
  Circle,
  Container,
  Description,
  GettingStarted,
  GlassDescription,
  GlassSecDescription,
  GlassTitle,
  GreenBg,
  Heading,
  Hero,
  Image,
  LightGlassV,
  Section,
  TextArea,
} from "./styles/research";

export default function Research({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Research.Heading = function ResearchHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Research.Description = function ResearchDescription({
  children,
  ...restProps
}) {
  return <Description {...restProps}>{children}</Description>;
};

Research.Image = function ResearchImage({ src, children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src={`/images/${src}.png`} alt="Research" />
    </Image>
  );
};

Research.CardImage = function ResearchCardImage({
  src,
  children,
  ...restProps
}) {
  return (
    <CardImage {...restProps}>
      <img src={`/images/${src}.png`} alt="Research" />
    </CardImage>
  );
};

Research.Section = function ResearchSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Research.GreenBg = function ResearchGreenBg({ children, ...restProps }) {
  return <GreenBg {...restProps}>{children}</GreenBg>;
};

Research.Hero = function ResearchHero({ children, ...restProps }) {
  return <Hero {...restProps}>{children}</Hero>;
};

Research.GettingStarted = function ResearchGettingStarted({
  children,
  ...restProps
}) {
  return (
    <GettingStarted {...restProps}>
      <h2>Getting Started</h2>
      <p>
        Interested in learning more about how Chimerocyte’s chimerism tests can
        advance your research? Contact us today and we’ll be in touch soon.
      </p>
      {children}
    </GettingStarted>
  );
};

Research.TextArea = function ResearchTextArea({ children, ...restProps }) {
  return <TextArea {...restProps}>{children}</TextArea>;
};

Research.Cards = function ResearchCards({ children, ...restProps }) {
  return <Cards {...restProps}>{children}</Cards>;
};

Research.LightGlassV = function ResearchLightGlassV({
  children,
  ...restProps
}) {
  return <LightGlassV {...restProps}>{children}</LightGlassV>;
};

Research.GlassTitle = function ResearchGlassTitle({ children, ...restProps }) {
  return <GlassTitle {...restProps}>{children}</GlassTitle>;
};

Research.GlassDescription = function ResearchGlassDescription({
  children,
  ...restProps
}) {
  return <GlassDescription {...restProps}>{children}</GlassDescription>;
};

Research.GlassSecDescription = function ResearchGlassSecDescription({
  children,
  ...restProps
}) {
  return <GlassSecDescription {...restProps}>{children}</GlassSecDescription>;
};

Research.Circle = function ResearchCircle({
  top,
  right,
  width,
  height,
  background,
  children,
  ...restProps
}) {
  return (
    <Circle
      top={top}
      right={right}
      width={width}
      height={height}
      background={background}
      {...restProps}
    >
      {children}
    </Circle>
  );
};
