import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 11vh;
  background: ${({ background }) => background};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  position: relative;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 183px;
  height: 60px;
  border-radius: 50px;
  background: ${({ logoBackground }) => logoBackground};

  img {
    width: 83%;
    height: auto;
  }

  @media (max-width: 500px) {
    width: 135px;
    height: 44px;
  }
`;

export const Links = styled.div`
  width: 50%;
  height: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #fffffc;
  box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05);
  border-radius: 40px;
  padding: 0 0.5rem;

  @media (max-width: 1400px) {
    height: 52%;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Link = styled.h3`
  font-family: Roboto;
  font-weight: 500;
  font-size: 16px;
  color: #376092;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    color: #6fcf97;
  }

  @media (max-width: 1600px) {
    font-size: 15px;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
`;

export const ProfileDropdown = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  cursor: pointer;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: 1.4rem;
  background: linear-gradient(89.5deg, #acf1ac -6.19%, #6fcf97 158.69%);

  @media (max-width: 1250px) {
    height: 45px;
    width: 45px;
  }

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }

  i {
    font-size: 20px;
    color: #fffffc;

    @media (max-width: 1250px) {
      font-size: 17px;
    }

    @media (max-width: 500px) {
      font-size: 14px;
    }
  }

  div {
    width: 300px;
    height: 370px;
    background: #fffffc;
    display: ${({ showProfile }) => (showProfile ? "flex" : "none")};
    position: absolute;
    ${"" /* margin-top: 0.3rem; */}
    top: 110%;
    z-index: 100;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s ease display;
    box-shadow: 0px 15px 60px rgba(57, 57, 57, 0.1);
    border-radius: 15px;
    ${"" /* padding: 2rem 0; */}
    cursor: default;

    h3 {
      font-family: Roboto;
      font-weight: 500;
      font-size: 16px;
      color: #376092;
      transition: all 0.5s ease;
      background: #ebf5f2;
      padding: 1rem 1.5rem;
      border-radius: 30px;
      ${"" /* box-shadow: 0px 5px 35px rgba(57, 57, 57, 0.05); */}

      @media (max-width: 1600px) {
        font-size: 15px;
      }

      @media (max-width: 1400px) {
        font-size: 14px;
      }
    }

    p {
      font-family: Roboto;
      font-weight: 500;
      font-size: 14px;
      color: ${({ verified }) => (verified ? "#6fcf97" : "red")};
      background: #ebf5f2;
      padding: 1rem 1.5rem;
      border-radius: 30px;

      @media (max-width: 1600px) {
        font-size: 13px;
      }

      @media (max-width: 1400px) {
        font-size: 12px;
      }
    }
  }

  ${
    "" /* &:hover {
    div {
      display: flex;
    }
  } */
  }
`;

export const DropdownLink = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease all;
  cursor: pointer;

  h3 {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: #376092;
    transition: all 0.5s ease;
    margin-right: 0.5rem;

    &:hover {
      color: #6fcf97;
    }

    @media (max-width: 1600px) {
      font-size: 15px;
    }

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  i {
    font-size: 13px;
    color: #376092;
    transition: all 0.5s ease;

    &:hover {
      color: #6fcf97;
    }

    @media (max-width: 1400px) {
      font-size: 12px;
    }
  }

  div {
    width: 204px;
    height: 100px;
    background: #fffffc;
    display: none;
    position: absolute;
    ${"" /* margin-top: 0.3rem; */}
    top: 110%;
    z-index: 3;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: 0.5s ease display;
    box-shadow: 0px 15px 60px rgba(57, 57, 57, 0.1);
    border-radius: 15px;
    padding: 0.6rem 0;
  }

  &:hover {
    div {
      display: flex;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  padding: 0 3rem;
  height: 48px;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 0 0.5rem;
  cursor: pointer;

  @media (max-width: 1250px) {
    padding: 0 2.6rem;
    height: 45px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 0 2.4rem;
    height: 40px;
    font-size: 14px;
  }
`;
