import React from "react";

import {
  Container,
  Hero,
  Section,
  Inputs,
  NameInputs,
  Input,
  Message,
  Heading,
  MapArea,
  DirectionsBtn,
  FullInput,
} from "./styles/contact";

export default function Contact({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Contact.Hero = function ContactHero({ children, ...restProps }) {
  return (
    <Hero {...restProps}>
      <i class="fas fa-map-marked-alt"></i>
      <h3>Fluke Hall 4000 Mason Rd, Seattle, WA 98195</h3>
      <p>info@chimerocyte.com</p>
      {children}
    </Hero>
  );
};

Contact.Section = function ContactSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Contact.Inputs = function ContactInputs({ children, ...restProps }) {
  return <Inputs {...restProps}>{children}</Inputs>;
};

Contact.MapArea = function ContactMapArea({ children, ...restProps }) {
  return <MapArea {...restProps}>{children}</MapArea>;
};

Contact.NameInputs = function ContactNameInputs({ children, ...restProps }) {
  return <NameInputs {...restProps}>{children}</NameInputs>;
};

Contact.Input = function ContactInput({ header, children, ...restProps }) {
  return (
    <Input header={header} {...restProps}>
      <h3>{header}</h3>
      <input placeholder={header} />
    </Input>
  );
};

Contact.FullInput = function ContactInput({ header, children, ...restProps }) {
  return (
    <FullInput header={header} {...restProps}>
      <h3>{header}</h3>
      <input placeholder={header} />
    </FullInput>
  );
};

Contact.Heading = function ContactHeading({ children, ...restProps }) {
  return <Heading {...restProps}>{children}</Heading>;
};

Contact.DirectionsBtn = function ContactDirectionsBtn({
  background,
  href,
  children,
  ...restProps
}) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <DirectionsBtn background={background} {...restProps}>
        {children}
      </DirectionsBtn>
    </a>
  );
};

Contact.Message = function ContactMessage({ header, children, ...restProps }) {
  return (
    <Message header={header} {...restProps}>
      <h3>{header}</h3>
      <textarea placeholder={header} />
    </Message>
  );
};
