import React from "react";

import { Container, Image, Title } from "./styles/workflow";

export default function Workflow({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Workflow.Image = function WorkflowImage({ children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src="/images/Workflow.png" alt="Workflow" />
    </Image>
  );
};

Workflow.Title = function WorkflowTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};
