import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import * as ROUTES from "../../constants/routes";

import {
  Container,
  Section,
  Picture,
  Input,
  Message,
  Visual,
  Links,
  Link,
  DropdownLink,
  Inputs,
  Button,
  Image,
} from "./styles/login";

export default function Login({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Login.Visual = function LoginVisual({ children, ...restProps }) {
  return <Visual {...restProps}>{children}</Visual>;
};

Login.Button = function LoginButton({
  onClick,
  background,
  children,
  ...restProps
}) {
  return (
    <Button onClick={onClick} background={background} {...restProps}>
      {children}
    </Button>
  );
};

Login.Section = function LoginSection({
  paddingRight,
  paddingLeft,
  children,
  ...restProps
}) {
  return (
    <Section
      paddingRight={paddingRight}
      paddingLeft={paddingLeft}
      {...restProps}
    >
      {children}
    </Section>
  );
};

Login.Picture = function LoginPicture({
  top,
  left,
  src,
  children,
  ...restProps
}) {
  return (
    <Picture top={top} left={left} {...restProps}>
      <img src={`/images/${src}.png`} alt={src} />
    </Picture>
  );
};

Login.Image = function LoginImage({ src, children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src={`/images/${src}.png`} alt={src} />
    </Image>
  );
};

Login.Inputs = function LoginInputs({ children, ...restProps }) {
  return <Inputs {...restProps}>{children}</Inputs>;
};

Login.Input = function LoginInput({
  error,
  value,
  setValue,
  placeholder,
  type,
  children,
  ...restProps
}) {
  return (
    <Input
      onChange={(event) => setValue(event.target.value)}
      value={value}
      placeholder={placeholder}
      type={type}
      error={error}
      {...restProps}
    >
      {children}
    </Input>
  );
};

Login.Message = function LoginMessage({ children, ...restProps }) {
  return (
    <Message {...restProps}>
      <p>{children}</p>
    </Message>
  );
};

Login.Links = function LoginLinks({ marginBottom, children, ...restProps }) {
  return (
    <Links marginBottom={marginBottom} {...restProps}>
      {children}
    </Links>
  );
};

Login.Link = function LoginLink({ to, children, ...restProps }) {
  return (
    <ReactRouterLink to={to}>
      <Link {...restProps}>{children}</Link>
    </ReactRouterLink>
  );
};

Login.DropdownLink = function LoginDropdownLink({ children, ...restProps }) {
  return (
    <DropdownLink {...restProps}>
      <h3>{children}</h3>
      <i class="fas fa-chevron-down"></i>
      <div>
        <ReactRouterLink to={ROUTES.ONCOLOGY}>
          <Link>Oncology Services</Link>
        </ReactRouterLink>
        <ReactRouterLink to={ROUTES.RESEARCH}>
          <Link>Research Services</Link>
        </ReactRouterLink>
      </div>
    </DropdownLink>
  );
};
