import styled from "styled-components/macro";

export const Requirement = styled.p`
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  line-height: 16px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  color: ${({ passed }) => (passed ? "#6FCF97" : "#c4c4c4")};

  @media (max-width: 1100px) {
    text-align: center;
  }

  @media (max-width: 750px) {
    font-size: 0.7rem;
  }
`;
