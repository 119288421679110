import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fcffff;
  padding: 4rem 0;
`;

export const Glass = styled.div`
  position: relative;
  z-index: 3;
  width: 80%;
  height: 750px;
  background: rgba(21, 21, 21, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.07);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 40px;
  border: 1.3px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6rem;

  @media (max-width: 1400px) {
    width: 90%;
  }

  @media (max-width: 1000px) {
    height: 700px;
    width: 85%;
  }
`;

export const Image = styled.img`
  width: 600px;
  height: auto;

  @media (max-width: 1400px) {
    width: 500px;
  }

  @media (max-width: 1000px) {
    width: 400px;
  }

  @media (max-width: 600px) {
    width: 300px;
  }

  @media (max-width: 400px) {
    width: 200px;
  }
`;

export const BgImageOne = styled.img`
  position: absolute;
  width: 500px;
  height: auto;
  top: 23%;
  right: 20%;

  @media (max-width: 1600px) {
    right: 15%;
  }

  @media (max-width: 1400px) {
    width: 400px;
    top: 30%;
  }

  @media (max-width: 1000px) {
    width: 300px;
  }

  @media (max-width: 800px) {
    right: 50%;
    left: 50%;
  }

  @media (max-width: 800px) {
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    top: 22%;
  }

  @media (max-width: 600px) {
    width: 200px;
    top: 30%;
  }

  @media (max-width: 400px) {
    width: 150px;
  }
`;

export const BgImageTwo = styled.img`
  position: absolute;
  width: 500px;
  height: auto;
  top: 55%;
  right: 55%;

  @media (max-width: 1600px) {
    right: 50%;
  }

  @media (max-width: 1400px) {
    width: 400px;
    top: 60%;
  }

  @media (max-width: 1000px) {
    width: 300px;
  }

  @media (max-width: 800px) {
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    top: 65%;
  }

  @media (max-width: 600px) {
    width: 200px;
  }

  @media (max-width: 400px) {
    width: 150px;
  }
`;
