import React from "react";

import {
  Container,
  Title,
  Image,
  Description,
  SecDescription,
  Text,
  Section,
} from "./styles/values";

export default function Values({ children, ...restProps }) {
  return <Container {...restProps}>{children}</Container>;
}

Values.Title = function ValuesTitle({ children, ...restProps }) {
  return <Title {...restProps}>{children}</Title>;
};

Values.Section = function ValuesSection({ children, ...restProps }) {
  return <Section {...restProps}>{children}</Section>;
};

Values.Image = function ValuesImage({ src, children, ...restProps }) {
  return (
    <Image {...restProps}>
      <img src={`/images/${src}.png`} alt="Values" />
    </Image>
  );
};

Values.Text = function ValuesText({ children, ...restProps }) {
  return <Text {...restProps}>{children}</Text>;
};

Values.Description = function ValuesDescription({
  alignSelf,
  textAlign,
  children,
  ...restProps
}) {
  return (
    <Description alignSelf={alignSelf} textAlign={textAlign} {...restProps}>
      {children}
    </Description>
  );
};

Values.SecDescription = function ValuesSecDescription({
  alignSelf,
  textAlign,
  children,
  ...restProps
}) {
  return (
    <SecDescription alignSelf={alignSelf} textAlign={textAlign} {...restProps}>
      {children}
    </SecDescription>
  );
};
