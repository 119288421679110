import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  background: #fcffff;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 5rem;
`;

export const Heading = styled.h1`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
  color: #376092;
  margin-bottom: 1rem;
`;

export const SecHeader = styled.h3`
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  color: #6fcf97;
  margin-bottom: 9rem;
`;

export const Input = styled.input`
  background: linear-gradient(
    90deg,
    #e9f1f4 -16.81%,
    rgba(233, 244, 242, 0.3) 100%
  );
  box-shadow: 0px 5px 25px rgba(57, 57, 57, 0.02);
  border-radius: 100px;
  width: 45%;
  height: 70px;
  margin: 2rem 0;
  outline: none;
  border: 1.3px solid rgba(202, 202, 202, 0.1);
  ${
    "" /* ${({ error }) =>
    error
      ? `
    border: 1.3px solid rgba(246, 36, 89, 1);
  `
      : `border: 1.3px solid rgba(202, 202, 202, 0.1);`} */
  }

  padding: 0 2rem;
  transition: 0.5s ease all;

  &:hover,
  &:focus {
    background: rgba(252, 252, 248, 1);
  }

  @media (max-width: 1500px) {
    height: 60px;
    font-size: 14px;
  }

  @media (max-width: 750px) {
    height: 55px;
    font-size: 13px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ background }) => background};
  color: #fffffc;
  padding: 0 2.5rem;
  height: 49px;
  font-size: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  border-radius: 40px;
  margin: 1rem 0;
  cursor: pointer;

  &:disabled {
    background: linear-gradient(
      89.5deg,
      rgba(172, 241, 172, 0.5) -6.19%,
      rgba(111, 207, 151, 0.5) 158.69%
    );
    cursor: default;
    box-shadow: none;
  }

  @media (max-width: 1250px) {
    padding: 0 2.6rem;
    height: 45px;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 0 2.4rem;
    height: 40px;
    font-size: 14px;
  }
`;
