import styled from "styled-components/macro";

export const Container = styled.div`
  width: 100%;
  height: 30vh;
  background-image: url("/images/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  padding: 0 13rem;
  margin-bottom: 5rem;
  position: relative;

  @media (max-width: 1500px) {
    padding: 0 6rem;
  }

  @media (max-width: 1100px) {
    padding: 0 3rem;
  }
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;

  h3 {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: #6d6d6d;
    margin-bottom: 1rem;
    width: 80%;

    span {
      color: #6fcf97;
    }

    @media (max-width: 1350px) {
      font-size: 18px;
    }

    @media (max-width: 1100px) {
      font-size: 16px;
    }
  }

  p {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #255591;

    @media (max-width: 1350px) {
      font-size: 16px;
    }

    @media (max-width: 1100px) {
      font-size: 14px;
    }
  }
`;

export const Profile = styled.div`
  width: 7.5vw;
  height: 7.5vw;
  border-radius: 500px;
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 10px rgba(57, 57, 57, 0.15);
  z-index: 2;

  img {
    width: 90%;
    height: auto;
    border-radius: 50%;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};

  img {
    width: ${({ width }) => width};
    height: auto;

    @media (max-width: 650px) {
      width: 200px;
    }
  }
`;
